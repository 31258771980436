var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"size":"xl","title":"Xem hồ sơ cần nộp","centered":"","hide-footer":""}},[_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTableModal || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):(props.column.field == 'action')?_c('span',[(props.row.fileId !=='00000000-0000-0000-0000-000000000000')?_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tải xuống'),expression:"'Tải xuống'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"DownloadIcon","size":"18"},on:{"click":function($event){return _vm.downloadFile(props.row)}}})],1):_vm._e()]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }