<template>
  <div>
    <b-modal
      :id="id"
      size="xl"
      title="Xem hồ sơ cần nộp"
      centered
      hide-footer
    >
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTableModal || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- tên -->
          <span v-if="props.column.field == 'name'">
            {{ props.row.name }}
          </span>

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span v-if="props.row.fileId !=='00000000-0000-0000-0000-000000000000'">
              <feather-icon
                v-b-tooltip.hover.top="'Tải xuống'"
                icon="DownloadIcon"
                size="18"
                @click="downloadFile(props.row)"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
    </b-modal>
  </div>
</template>
<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    dataTableModal: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      showBtnMultiDelete: false,
      columns: [
        {
          label: 'STT',
          field: 'index',
        },
        {
          label: 'TÊN FILE',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      arrayExcel: null,
    }
  },
  methods: {
    // Tải xuống tệp tin
    async downloadFile(fileInfo) {
      try {
        await axiosApiInstance({
          url: ConstantsApi.DOWNLOAD_FILE_REQUIRES,
          method: 'GET',
          responseType: 'blob', // important
          params: { fileId: fileInfo.fileId },
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileInfo.fileName) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
      } catch {
        this.$root.$bvToast.toast('Tải xuống không thành công, vui lòng thử lại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // tìm kiếm tên file trong danh sách
    search(val) {
      this.$emit('searchFile', val)
    },

    // Phân trang danh sách file
    handlePageClick(pageNumber, pageSize) {
      this.$emit('handlePageClickPage', pageNumber, pageSize)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
</style>
